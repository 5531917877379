import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React, { useRef, useState } from 'react'

const passcode = '0531';

const PasswordChecker = () => {

  const [open, setOpen] = useState(true);
  const input = useRef(null);

  return (<Dialog
    open={open}
  >
    <DialogTitle>
      パスワードを入力してください
    </DialogTitle>
    <DialogContent>
      <TextField
        label="パスワード"
        type="tel"
        autoFocus
        fullWidth
        sx={{
          mt: 1,
        }}
        inputRef={input}
      />
    </DialogContent>
    <DialogActions sx={{
      justifyContent: 'center',
      pb: 2,
    }}>
      <Button
        variant='contained'
        disableElevation
        onClick={() => {
          if (input.current.value === passcode) {
            setOpen(false);
          }else{
            alert("パスワードが違います");
          }
        }}
      >
        確認
      </Button>
    </DialogActions>
  </Dialog>)
}

export default PasswordChecker