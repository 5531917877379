import { Box, Button } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import studentAttendanceRequest from '../functions/studentAttendanceRequest';
import ResultDialog from './ResultDialog';

const NfcReader = ({
  isReded,
  onReaded,
}) => {

  const [isReader, setIsReaderState] = useState(false);
  const [status, setStatus] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("リクエスト中です");
  const [dialogBody, setDialogBody] = useState("");
  const nfcReader = useRef();


  useEffect(() => {
    try {
      nfcReader.current = new window.NDEFReader();
      setIsReaderState(true);
    }catch(e) {
      console.log("NFCリーダーが見つかりませんでした");
    }
  }, []);

  const handleClick = () => {
    // NFCリーダー非対応
    if (isReader === false) return;
    if (status) return;

    nfcReader.current.scan().then(() => {
      setStatus(true);

      // 読み込みエラー
      nfcReader.current.onreadingerror = (e) => {
        alert(`エラー:\n${e.message}`);
      }

      // 読み込みイベント
      nfcReader.current.onreading = (e) => {
        if (!isReded) {
          onReaded(true);
          const serialNumber = e.serialNumber;

          studentAttendanceRequest(serialNumber).then((res) => {
            // 成功
            const {
              student_name,
              attendance_type,
            } = res.attendance;

            setDialogTitle(`${(attendance_type === 'entry')? "入室": "退室"}しました`);
            setDialogBody(`生徒名: ${student_name}`);
            setOpenDialog(true);
          }).catch((e) => {
            // 失敗
            setDialogTitle(`エラー(${e.code}): ${e.message}`);
            setOpenDialog(true);
          });
        }
      }
    }).catch((e) => {
      alert(`エラー:\n${e.message}`);
      setStatus(false);
    });
  }
  

  return (<>
    <Button
      variant='contained'
      onClick={handleClick}
      disabled={!isReader || status}
    >
      NFCを読み取る
    </Button>

    <Box sx={{
      p: 2,
    }}>
      ステータス: {(status)? "読み取り中": "停止中"}
    </Box>

    {/* 結果表示ダイアログ */}
    <ResultDialog
      open={openDialog}
      onClose={() => {
        setOpenDialog(false);
        onReaded(false);
      }}
      title={dialogTitle}
    >
      {dialogBody}
    </ResultDialog>
  </>);
}

export default NfcReader