import { Backdrop, Box, CircularProgress } from '@mui/material'
import React from 'react'

const Loading = ({
  open,
}) => {
  return (
    <Box>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </Box>
  )
}

export default Loading