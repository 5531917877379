import { AppBar, Box, Toolbar } from '@mui/material'
import React from 'react'

const Appbar = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Box>
          Fuchu入退室管理
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Appbar