import Appbar from './Component/Appbar';
import './App.css';
import { Box } from '@mui/material';
import NfcReader from './Component/NfcReader';
import { useState } from 'react';
import Loading from './Component/Loading';
import PasswordChecker from './Component/PasswordChecker';

function App() {

  const [readed, setReaded] = useState(false);

  return (<>
    <Appbar />

    <Box sx={{
      p: 2,
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }}>
      <NfcReader
        isReaded={readed}
        onReaded={setReaded}
      />
    </Box>

    <PasswordChecker />


    {/* Loader */}
    <Loading open={readed} />
  </>);
}

export default App;
