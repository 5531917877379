import { Button, Dialog, DialogActions, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

const ResultDialog = ({
  open,
  children,
  title,
  onClose,
}) => {
  return (
    <Dialog
      open={open}
    >
      <DialogTitle sx={{
        textAlign: 'center',
      }}>
        {title}
      </DialogTitle>
      <DialogContentText sx={{p: 3, minWidth: "200px"}}>
        {children}
      </DialogContentText>
      <DialogActions>
        <Button
          onClick={onClose}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ResultDialog