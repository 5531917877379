
const studentAttendanceRequest = (serial) => {
    return new Promise((resolve, reject) => {
        //
        fetch(
            "https://fuchu-api.fan-mily.com/api/v1/attendance",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    location_id: "0000000",
                    nfc_serial_number: serial,
                }),
            }
        ).then(async (response) => {
            const res = await response.json();

            if (!response.ok) {
                reject(res);
                return;
            }

            resolve(res);
        }).catch((err) => {
            reject(err);
        });
    });
}

export default studentAttendanceRequest;